// Color guide (figma)
// Brand colors
$brand-blue: #177be1;
$brand-orange: #ef956f;

// Text colors
$text-black: #333;
$gray-01: #828282;
$gray-02: #bdbdbd;
$gray-03: #e7e7e7;
$gray-04: #fafafa;
$true-black: #000;

// Secondary colors
$deep-blue: #185888;
$deep-orange: #e85319;
$secondary-blue: #adccff;
$secondary-orange: #fbbba0;
$background-blue: #f3faff;
$background-orange: #ffe5da;
$error-red: #f61e5f;
$verified-green: #15a612;

// Tertiary & illustrations
$gentle-green: #edf6c6;
$bright-green: #72dbbb;
$mustard-yellow: #f3c477;
$warm-brown: #be6373;
$pale-pink: #eee0e4;
$beige: #fff6e8;

// Custom bootstrap color
$white:    #fff !default;
// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
$gray-900: $text-black;
$black: $true-black;

// $grays: () !default;
// $grays: map-merge(
//   (
//     "100": $gray-100,
//     "200": $gray-200,
//     "300": $gray-300,
//     "400": $gray-400,
//     "500": $gray-500,
//     "600": $gray-600,
//     "700": $gray-700,
//     "800": $gray-800,
//     "900": $gray-900
//   ),
//   $grays
// );
// $blue:    #007bff !default;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #e83e8c !default;
// $red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
// $green:   #28a745 !default;
// $teal:    #20c997 !default;
// $cyan:    #17a2b8 !default;

// $colors: () !default;
// $colors: map-merge(
//   (
//     "blue":       $blue,
//     "indigo":     $indigo,
//     "purple":     $purple,
//     "pink":       $pink,
//     "red":        $red,
//     "orange":     $orange,
//     "yellow":     $yellow,
//     "green":      $green,
//     "teal":       $teal,
//     "cyan":       $cyan,
//     "white":      $white,
//     "gray":       $gray-600,
//     "gray-dark":  $gray-800
//   ),
//   $colors
// );

$primary: $brand-blue;
$secondary: $background-blue;
$success: $verified-green;
// $info:          $cyan !default;
// $warning:       $yellow !default;
$danger: $error-red;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

// $theme-colors: () !default;
// $theme-colors: map-merge(
//   (
//     "primary":    $primary,
//     "secondary":  $secondary,
//     "success":    $success,
//     "info":       $info,
//     "warning":    $warning,
//     "danger":     $danger,
//     "light":      $light,
//     "dark":       $dark
//   ),
//   $theme-colors
// );

// // Set a specific jump point for requesting color jumps
// $theme-color-interval:      8% !default;

// // The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
// $yiq-contrasted-threshold:  150 !default;

// // Customize the light and dark text colors for use in our YIQ color contrast function.
// $yiq-text-dark:             $gray-900 !default;
// $yiq-text-light:            $white !default;
