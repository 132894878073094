// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.75rem;
// $input-btn-font-family:       null !default;
$input-btn-font-size: $font-size-sm;
$input-btn-line-height: 20 / 14;

// $input-btn-focus-width:       .2rem  !default;
// $input-btn-focus-color:       rgba($component-active-bg, .25) !default;
// $input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

// $input-btn-padding-y-sm:      .25rem !default;
// $input-btn-padding-x-sm:      .5rem !default;
// $input-btn-font-size-sm:      $font-size-sm !default;
// $input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 0.5rem;
// $input-btn-font-size-lg:      $font-size-lg !default;
// $input-btn-line-height-lg:    $line-height-lg !default;

// $input-btn-border-width:      $border-width !default;

// // Buttons
// //
// // For each of Bootstrap's buttons, define text, background, and border color.

// $btn-padding-y:               $input-btn-padding-y !default;
// $btn-padding-x:               $input-btn-padding-x !default;
// $btn-font-family:             $input-btn-font-family !default;
// $btn-font-size:               $input-btn-font-size !default;
// $btn-line-height:             $input-btn-line-height !default;
// $btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

// $btn-padding-y-sm:            $input-btn-padding-y-sm !default;
// $btn-padding-x-sm:            $input-btn-padding-x-sm !default;
// $btn-font-size-sm:            $input-btn-font-size-sm !default;
// $btn-line-height-sm:          $input-btn-line-height-sm !default;

// $btn-padding-y-lg:            $input-btn-padding-y-lg !default;
// $btn-padding-x-lg:            $input-btn-padding-x-lg !default;
// $btn-font-size-lg:            $input-btn-font-size-lg !default;
// $btn-line-height-lg:          $input-btn-line-height-lg !default;

// $btn-border-width:            $input-btn-border-width !default;

// $btn-font-weight:             $font-weight-normal !default;
// $btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
// $btn-focus-width:             $input-btn-focus-width !default;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 1;
// $btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

// $btn-link-disabled-color:     $gray-600 !default;

// $btn-block-spacing-y:         .5rem !default;

// // Allows for customizing button radius independently from global border radius
// $btn-border-radius:           $border-radius !default;
// $btn-border-radius-lg:        $border-radius-lg !default;
// $btn-border-radius-sm:        $border-radius-sm !default;

// $btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
