// Buttons
.btn {
  overflow: hidden;
  text-overflow: ellipsis;
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $gray-02;
    border-color: $gray-02;
    pointer-events: none;
  }

  &-primary {
    border-color: var(--bright-orange);
    background-color: var(--bright-orange);

    &:focus,
    &:hover {
      border-color: $deep-orange;
      background-color: $deep-orange;
    }

    &:not(:disabled):not(.disabled):active {
      border-color: $deep-orange;
      background-color: $deep-orange;
    }
  }

  &-outline-primary {
    &:not(:disabled):not(.disabled):active {
      color: $white;
    }
  }

  &-secondary {
    background-color: $gray-02;
    &:not(:disabled) {
      color: $white !important;
    }

    &:focus {
      border-color: $gray-02;
    }

    &:hover,
    &:focus {
      background-color: $gray-01;
      border-color: $gray-01;
    }
  }

  &-link {
    border-width: 0;
    // padding: 0;

    &:disabled {
      color: $gray-02;
      background-color: inherit;
    }
  }
}
