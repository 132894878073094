@import "src/styles/functions";

.loading {
  width: toRem(100);
  text-align: center;
  margin: toRem(100) auto;

  &__spinner {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}