.layout {
  // need to set the body font since some elements are using react portal and mounted in the body
  @at-root body {
    font-family: "Noto Sans", sans-serif;
  }
  width: 100%;
  // height: 100vh;
  // overflow-x: hidden;
}

.font--phone {
  font-family: "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}
