// import bootstrap
@use "./styles/bootstrap" as *;
@use "./styles/fonts.scss" as *;
@use "./styles/spacings.css" as *;
@use "src/styles/functions" as *;

:root {
  --pale-blue: #f2f7ff;
  --pale-orange: #ffe5da;
  --orange: #f09e77;
  --deep-orange: #{$deep-orange};
  --bright-orange: #fb6b13;
  --secondary-orange: #{$secondary-orange};
  --yellow: #ffd300;
  --dark-yellow: #f7bf59;
  --light-blue: #caddfd;
  --blue: #1778e1;
  --bg-blue: #f3faff;
  --bg-orange: #ffe5da;
  --deep-blue: #185888;
  --secondary-blue: #{$secondary-blue};
  --red: #ef0c79;
  --verified-green: #{$verified-green};
  --gentle-green: #{$gentle-green};
  --teal: #11cdef;
  --cyan: #72dbbb;
  --white: #{$white};
  --gray: #8898aa;
  --gray01: #{$gray-01};
  --gray02: #{$gray-02};
  --gray03: #{$gray-03};
  --gray04: #fafafa;
  --system-gray: #8e8e93;
  --light-gray: #a3a3a3;
  --light: #f4f7fb;
  --lighter: #e9ecef;
  --primary: #{$primary};
  --secondary: #f4f5f7;
  --success: #2dce89;
  --info: #11cdef;
  --warning: #fb6340;
  --danger: #f5365c;
  --dark: #4f4f4f;
  --default: #172b4d;
  --neutral: #fff;
  --darker: #{$text-black};
  --tints: #007aff;
  --error-red: #{$error-red};
}

html {
  font-size: 16px;

  // customize scroll bar
  *::-webkit-scrollbar {
    width: toRem(6);
    height: toRem(6);
  }

  *::-webkit-scrollbar-track {
    background: #eee;
    // margin-left: toRem(15);
    // margin-right: toRem(15);
  }

  *::-webkit-scrollbar-thumb {
    border-radius: toRem(20);
    border: toRem(3) solid rgba(189, 189, 189, 0.6);
  }

  .dropdown-toggle::after {
    display: none;
  }

  input[type="checkbox"]:disabled:not(:checked) + label:before,
  input[type="checkbox"]:disabled:checked + label:before {
    border: 1px solid var(--gray02);
    border-radius: 25px;
    background-color: var(--gray02);
  }

  input[type="checkbox"]:not(:checked) + label:before,
  input[type="checkbox"]:checked + label:before {
    border: 1px solid var(--blue);
    border-radius: 25px;
  }
}

@media only screen and (max-width: 767.98px) and (orientation: portrait) {
  h1 {
    font-size: 1.75rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
}

body {
  color: var(--darker);
  background-color: white;
}

.questrial {
  font-family: "Questrial", sans-serif !important;
}

.secondary-orange {
  color: var(--secondary-orange) !important;
}

.primary {
  color: var(--primary) !important;
}

.white {
  color: white !important;
}

.blue {
  color: var(--blue);
}

.deep-blue {
  color: var(--deep-blue);
}

.red {
  color: var(--red);
}

.orange {
  color: var(--orange);
}

.deep-orange {
  color: var(--deep-orange);
}

.dark {
  color: var(--dark);
}

.darker {
  color: var(--darker);
}

.green {
  color: var(--verified-green);
}

.gray01 {
  color: var(--gray01);
}

.gray02 {
  color: var(--gray02) !important;
}

.gray04 {
  color: var(--gray04);
}

.light-gray {
  color: var(--light-gray);
}

a:hover {
  text-decoration: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.required {
  color: red;
}

.navbar-collapse.collapsing,
.navbar-collapse.show {
  padding: 1.5rem;
  border-radius: 0.25rem;
  background: white;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  animation: show-navbar-collapse 0.2s ease forwards;
}

/*
 * flex
*/

ul.a {
  list-style-type: circle;
}

ul.no-bullets {
  list-style-type: none;
}

ol.lower-alpha {
  list-style-type: lower-alpha;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--red);
}

.was-validated .form-control:invalid:focus {
  border-color: var(--red);
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: var(--verified-green);
}

.was-validated .form-control:valid:focus {
  border-color: var(--verified-green);
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: var(--red);
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: var(--verified-green);
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  background-color: var(--verified-green);
  border-color: var(--verified-green);
}

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: var(--red);
}

.custom-select {
  border-color: var(--light-blue);
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: var(--red);
}

.was-validated .custom-select:invalid:focus {
  border-color: var(--red);
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: var(--verified-green);
}

.was-validated .custom-select:valid:focus {
  border-color: var(--verified-green);
}

.text-success {
  color: var(--verified-green) !important;
}

.text-danger {
  color: var(--red) !important;
}

.shadow-sm {
  box-shadow: 0px 2px 4px rgba(74, 74, 74, 0.15) !important;
}

.shadow {
  box-shadow: 0px 2.5px 0px rgba(0, 0, 0, 0.13) !important;
}

.disabled-link {
  pointer-events: none;
  color: var(--gray02);
  border-color: var(--gray02) !important;
}

.form-control {
  @include rg-14;
  border-color: #ADCCFF;
  height: 2.5rem;
}

.form-control::-webkit-input-placeholder {
  color: var(--gray02);
}
