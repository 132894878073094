@import "./mixins";
/* refer to ui design's typography  */
/* e.g. 24px medium => md-24 */

.rg-8 {
  @include rg-8;
}

.rg-9 {
  @include rg-9;
}

.md-24 {
  @include md-24;
}

.rg-18 {
  @include rg-18;
}

.md-18 {
  @include md-18;
}

.bd-18 {
  @include bd-18;
}

.rg-24 {
  @include rg-24;
}

.rg-32 {
  @include rg-32;
}

.rg-36 {
  @include rg-36;
}

.bd-32 {
  @include bd-32;
}

.rg-50 {
  @include rg-50;
}
.rg-40 {
  @include rg-40;
}

.bd-40 {
  @include bd-40;
}

.md-16 {
  @include md-16;
}

.md-14 {
  @include md-14;
}

.rg-16 {
  @include rg-16;
}

.bd-16 {
  @include bd-16;
}

.rg-14 {
  @include rg-14;
}

.bd-14 {
  @include bd-14;
}

.rg-12 {
  @include rg-12;
}

.bd-12 {
  @include bd-12;
}

.rg-11 {
  @include rg-11;
}

.md-28 {
  @include md-28;
}

.rg-17 {
  @include rg-17;
}

.bd-17 {
  @include bd-17;
}

.bd-20 {
  @include bd-20;
}

.rg-15 {
  @include rg-15;
}

.rg-20 {
  @include rg-20;
}

.bd-15 {
  @include bd-15;
}

.rg-13-footnote {
  @include rg-13-footnote;
}
