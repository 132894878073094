/* refer to ui design's typography  */
/* e.g. 24px medium => md-24 */

$font-weight-bd: 600;
$font-weight-md: 500;
$font-weight-rg: 400;
$font-questrial: "Questrial";

@mixin rg-8 {
  font-weight: $font-weight-rg;
  font-size: 8px;
  line-height: 18px;
}

@mixin md-28 {
  font-weight: $font-weight-md;
  font-size: 28px;
  line-height: 34px;
}

@mixin rg-24 {
  font-weight: $font-weight-rg;
  font-size: 24px;
  line-height: 34px;
}

@mixin md-24 {
  font-weight: $font-weight-md;
  font-size: 24px;
  line-height: 32px;
}

@mixin rg-32 {
  font-weight: $font-weight-rg;
  font-size: 32px;
  line-height: 42px;
}

@mixin bd-32 {
  font-weight: $font-weight-bd;
  font-size: 32px;
  line-height: 20px;
}

@mixin rg-50 {
  font-weight: $font-weight-rg;
  font-size: 50px;
  line-height: 62px;
}

@mixin rg-36 {
  font-weight: $font-weight-rg;
  font-size: 36px;
  line-height: 42px;
}

@mixin rg-40 {
  font-weight: $font-weight-rg;
  font-size: 40px;
  line-height: 47px;
}

@mixin bd-40 {
  font-weight: $font-weight-bd;
  font-size: 40px;
  line-height: 62px;
}

%phone-18 {
  font-size: 18px;
  line-height: 28px;
}

%phone-20 {
  font-size: 20px;
  line-height: 24px;
}

@mixin rg-20 {
  @extend %phone-20;
  font-weight: $font-weight-rg;
}

@mixin bd-20 {
  @extend %phone-20;
  font-weight: $font-weight-bd;
}

@mixin rg-18 {
  font-weight: $font-weight-rg;
  @extend %phone-18;
}

@mixin md-18 {
  font-weight: $font-weight-md;
  @extend %phone-18;
}

@mixin bd-18 {
  font-weight: $font-weight-bd;
  @extend %phone-18;
}

%phone-17 {
  font-size: 17px;
  line-height: 22px;
}

@mixin bd-17 {
  @extend %phone-17;
  font-weight: $font-weight-bd;
}

@mixin rg-17 {
  @extend %phone-17;
  font-weight: $font-weight-rg;
}

@mixin md-16 {
  font-weight: $font-weight-md;
  font-size: 16px;
  line-height: 20px;
}

@mixin rg-16 {
  font-weight: $font-weight-rg;
  font-size: 16px;
  line-height: 24px;
}

@mixin bd-16 {
  font-weight: $font-weight-bd;
  font-size: 16px;
  line-height: 22px;
}

%phone-15 {
  font-size: 15px;
  line-height: 20px;
}

@mixin rg-15 {
  @extend %phone-15;
  font-weight: $font-weight-rg;
}

@mixin bd-15 {
  @extend %phone-15;
  font-weight: $font-weight-bd;
}

@mixin bd-14 {
  font-weight: $font-weight-bd;
  font-size: 14px;
  line-height: 20px;
}

@mixin md-14 {
  font-weight: $font-weight-md;
  font-size: 14px;
  line-height: 20px;
}

@mixin rg-14 {
  font-weight: $font-weight-rg;
  font-size: 14px;
  line-height: 20px;
}

@mixin rg-13 {
  font-weight: $font-weight-rg;
  font-size: 13px;
  line-height: 18px;
}

@mixin rg-13-footnote {
  font-weight: $font-weight-rg;
  font-size: 13px;
  line-height: 16px;
}

@mixin bd-12 {
  font-weight: $font-weight-bd;
  font-size: 12px;
  line-height: 18px;
}

@mixin rg-12 {
  font-weight: $font-weight-rg;
  font-size: 12px;
  line-height: 18px;
}

@mixin rg-11-caption {
  font-weight: $font-weight-rg;
  font-size: 11px;
  line-height: 13px;
}

@mixin rg-9 {
  font-weight: $font-weight-rg;
  font-size: 9px;
  line-height: 16px;
}

@mixin rg-11 {
  font-weight: $font-weight-rg;
  font-size: 11px;
  line-height: 18px;
}

@mixin md-36 {
  font-weight: $font-weight-md;
  font-size: 36px;
  line-height: 32px;
}

// latest
@mixin caption-mobile {
  font-weight: $font-weight-rg;
  font-size: 11px;
  line-height: 16px;
}

%phone-sticky {
  position: fixed;
  z-index: 1000;
  background-color: var(--white);
  width: 100vw;
  display: flex;
}

@mixin phone-sticky-top {
  @extend %phone-sticky;
  box-shadow: 0 toRem(1) toRem(2) rgba(0, 0, 0, 0.1);
  top: 0;
}

@mixin phone-sticky-bottom {
  @extend %phone-sticky;
  bottom: 0;
}

// latest
@mixin header-alt {
  @include rg-24;

  line-height: 28px;
  font-family: $font-questrial;
  text-align: center;
}
