@import "src/styles/functions";

.logo-loading {
  position: relative;
  
  // assume font size is 16px
  min-width: toRem(24);
  min-height: toRem(24);
  max-width: toRem(100);
  max-height: toRem(100);
  aspect-ratio: 1 / 1;
}