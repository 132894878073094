@import "src/styles/constants";
@import "src/styles/functions";

.nav-bar {
  /* control height */
  height: $navbarHeight;
  flex: none;
  z-index: 1000;
  padding: toRem(16) toRem(32) toRem(16) 0;
  background-color: var(--blue);

  &-about-us {
    background-color: var(--white);
  }

  &--phone {
    padding-left: toRem(16);
    height: toRem(50);

    & :global(.container) {
      max-width: 100%;
    }
  }
}

.navbar-fixed {
  min-width: 100%;
  position: fixed;
  top: 0;
}
